import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect, useHistory,
} from "react-router-dom";

import Home from 'views/pages/main/home';
import Services from "views/pages/main/Services";

function App() {
  return (
    
    <Router>
       <Switch>
         <Route exact path="/">
            <Redirect to="/home" />
         </Route>
         <Route exact component={Services} path="/services" />
         <Route exact component={Home} path="/home" />    
       </Switch>
     </Router>
  );
}

export default App;
