import icon1 from "assets/icon/Iconly2.svg";
import icon2 from "assets/icon/Iconly3.svg";
import icon3 from "assets/icon/Iconly4.svg";
import icon4 from "assets/icon/Moaref.png";
import { Link } from "react-router-dom";

const FooterBox = () => {
  return (
      // <div className=" flex bg-gradient-to-r from-primary to-success rounded-md px-4 py-8 shadow-md font-IranYekan justify-between mt-4">
    <div className="px-4">
      <div className="font-IranYekan">
        <div className="bg-white py-3 px-4 rounded-md mt-4 flex justify-around shadow-md bottom-3.5  ">
          <Link to={"customerService"} >
          <div className="flex flex-col	items-center justify-items-center ">
            <div className="bg-[#00ABC1] p-5 rounded-xl mb-2 shadow-blueBox">
              <img src={icon3} />
            </div>
            <p className="font-normal	text-sm">امور مشتریان</p>
          </div>
          </Link>

          <Link to={"/site/ColleaguesInvitation/index"} >
          <div className="flex flex-col	items-center justify-items-center ">
            <div className="bg-[#00ABC1] p-5 rounded-xl mb-2 shadow-blueBox">
              <img src={icon4} />
            </div>
            <p className="font-normal	text-sm">معرفی</p>
          </div>
          </Link>

          <Link to={"site/consultDoctor"} >
          <div className="flex flex-col	items-center justify-items-center ">
            <div className="bg-[#00ABC1] p-5 rounded-xl mb-2 shadow-blueBox">
              <img src={icon2} />
            </div>
            <p className="font-normal	text-sm">ویزیت مجازی</p>
          </div>
          </Link>

          {/*<Link to={"Profile"} >*/}
          {/*  <div className="flex flex-col	items-center justify-items-center ">*/}
          {/*    <div className="bg-[#00ABC1] p-5 rounded-xl mb-2 shadow-blueBox">*/}
          {/*      <img src={icon1} />*/}
          {/*    </div>*/}
          {/*    <p className="font-normal	text-sm">پروفایل</p>*/}
          {/*  </div>*/}
          {/*</Link>*/}

        </div>
      </div>
    </div>
  );
};

export default FooterBox;
