import { NavLink } from "react-router-dom";
import { ReactComponent as Home } from "assets/icon/home.svg";
import { ReactComponent as Services } from "assets/icon/services.svg";

const AppTab = () => {
  return (
    <div className="bg-white flex font-IranYekan ">
      <NavLink
        to="/home"
        className="flex flex-col flex-1 text-center"
        activeClassName="activeTab text-primary "
      >
        <div className="flex justify-center items-center pb-1">
          <Home fill="currentColor" />
          <p className="mr-1">کلینیک</p>
        </div>
      </NavLink>

      <NavLink
        to="/services"
        className="flex flex-col flex-1 text-center"
        activeClassName="activeTab text-primary"
      >
        <div className="flex justify-center items-center pb-1">
          <Services fill="currentColor" />
          <p className="mr-1">خدمات</p>
        </div>
      </NavLink>
    </div>
  );
};

export default AppTab;
