import icon1 from "../../assets/icon/Iconly2.svg";
import icon2 from "../../assets/icon/Iconly3.svg";
import icon3 from "../../assets/icon/Iconly4.svg";
import { Link } from "react-router-dom";
import heart from "../../assets/icon/bi_balloon-heart.svg";
import React from "react";

const Footer = () => {
  return (
      // <div className=" flex bg-gradient-to-r from-primary to-success rounded-md px-4 py-8 shadow-md font-IranYekan justify-between mt-4">
    <div className="px-4">
      <div className=" flex justify-center items-center my-5 ">
        <img src={heart} />
        <p className="text-sm text-gray-600 mr-1">
          از خدمات کلینیک های ایران نوین لذت ببرید. v.1.1
        </p>
      </div>
    </div>
  );
};

export default Footer;
