import AppTab from "views/components/AppTab";
import {Link} from "react-router-dom";
import { ReactComponent as Logo } from "assets/icon/logo.svg";
import { ReactComponent as Wallet } from "assets/icon/wallet.svg";
import { ReactComponent as IconSupport } from "assets/icon/iconSupport.svg";
import { ReactComponent as IconProfile } from "assets/icon/Profile-profile.svg";
import Settings from "assets/icon/settings.png";

const Header = () => {
  return (
    <div className="bg-white  px-4 pb-0 pt-6  shadow-md sticky w-full top-0 z-50">
      <div className="flex justify-between mb-4">
        <Logo />
          <div className="flex">
              <Link to={"/Setting"}>

                  <div className="flex items-center justify-center bg-gray-200 rounded-full w-[46px] h-[46px] mx-1 relative">
                  <span className="bg-red-600  flex items-center justify-center rounded-full w-[22px] h-[22px] text-white absolute -top-2 -left-2">2</span>
                    <img src={Settings} className="justify-self-center w-3/4" />
                  </div>
                  
                
              </Link>
          </div>
      </div>
      <AppTab />
    </div>
  );
};

export default Header;
