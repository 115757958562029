import Header from "views/layouts/Header";
import Footer from "views/layouts/Footer";
// import Category from "views/layouts/Category";
import {useEffect,useState} from "react";
import axios from "axios";
import FooterBox from "views/layouts/FooterBox";
import { Link } from "react-router-dom";


import ArrowIcon from "assets/icon/arrow2.svg";
import HairIcon from "assets/icon/hair.svg";
import layzer from "assets/icon/servis-layzer.svg";
import javansazi from "assets/icon/servis-javansazi.svg";
import tanasob from "assets/icon/servis-tanasob.svg";
import zibaei from "assets/icon/servis-zibaei.svg";

const Category = () => {
  return (
      <div>
        <div className="font-IranYekan mb-2">
          <Link to="/services/5">
            <div className="bg-white rounded-md shadow-md p-3 flex justify-between items-center ">
              <img src={HairIcon} />
              <div>
                <p className="text-appGray-300 font-extrabold text-lg">مو</p>
                <p className="text-appGray-200 text-sm">
                  پیوند، ترمیم، کاشت و تقویت
                </p>
              </div>

              <img src={ArrowIcon} />
            </div>
          </Link>
        </div>

        <div className="font-IranYekan mb-2">
          <Link to="/services/5">
            <div className="bg-white rounded-md shadow-md p-3 flex justify-between items-center ">
              <img src={layzer} />
              <div>
                <p className="text-appGray-300 font-extrabold text-lg">لیزر موهای زائد</p>
                <p className="text-appGray-200 text-sm">
                  IPL, E-light, Nd-Yag, Dyuode
                </p>
              </div>

              <img src={ArrowIcon} />
            </div>
          </Link>
        </div>

        <div className="font-IranYekan mb-2">
          <Link to="/services/5">
            <div className="bg-white rounded-md shadow-md p-3 flex justify-between items-center ">
              <img src={javansazi} />
              <div>
                <p className="text-appGray-300 font-extrabold text-lg">جوان سازی</p>
                <p className="text-appGray-200 text-sm">
                  تزریق ژل، هایفوتراپی و دستگاه rf
                </p>
              </div>

              <img src={ArrowIcon} />
            </div>
          </Link>
        </div>


        <div className="font-IranYekan mb-2">
          <Link to="/services/5">
            <div className="bg-white rounded-md shadow-md p-3 flex justify-between items-center ">
              <img src={zibaei} />
              <div>
                <p className="text-appGray-300 font-extrabold text-lg">جراحی زیبایی</p>
                <p className="text-appGray-200 text-sm">
                  پروتز، ترمیم، لیفت و ... &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;
                </p>
              </div>

              <img src={ArrowIcon} />
            </div>
          </Link>
        </div>

        <div className="font-IranYekan mb-2">
          <Link to="/services/5">
            <div className="bg-white rounded-md shadow-md p-3 flex justify-between items-center ">
              <img src={tanasob} />
              <div>
                <p className="text-appGray-300 font-extrabold text-lg">تناسب اندام</p>
                <p className="text-appGray-200 text-sm">
                  بادی جت، پیکرتراشی، لیپوساکشن
                </p>
              </div>

              <img src={ArrowIcon} />
            </div>
          </Link>
        </div>
      </div>
  );
};


const Services = () => {
  const [services, setServices] = useState<any[]>([]);

  // useEffect(() => {
  //   genServices();
  // }, []);

  // const genServices = async () => {
  //   let services = await axios.get('http://adminapp.moohair.ir/api/v1/services');
  //   setServices(services.data.data);
  // };

  return (
    <>
      <Header />
        <div className="pt-6 pb-10">
          <div className="pt-6 pb-5 px-4">
              <Category  />
          </div>
          <FooterBox />
          <Footer />
        </div>
    </>
  );
};

export default Services;
