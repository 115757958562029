import Header       from "views/layouts/Header";
import FooterBox    from "views/layouts/FooterBox";
import Footer       from "views/layouts/Footer";
const Home = () => {
    return (
        <>
            <Header/>
            <h1>Hi</h1>
            <FooterBox/>
            <Footer/>
        </>
    );
};

export default Home;
